import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mobileThresholdPixels, margins } from './styledComponents';

import arrowLeft from '../../../tilli-components/src/assets/arrow.left.navy.svg';
import arrowRight from '../../../tilli-components/src/assets/arrow.right.navy.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: ${margins.m} ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: row;
    justify-content: space-around;
    margin: 0px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

const ControlContainer = styled.div`margin: 0px ${margins.s}`;

const ControlImg = styled.img`
  width: 20px;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 15px;
  }
`;

const Img = styled.img`
  width: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 60px;
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px ${margins.s};
  opacity: 0.4;
  ${props => props.isSelected && 'opacity: 1'};
  width: 150px;
  height: 150px;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 80px;
    height: 80px;
    margin: 0px;
  }
`;

class ArticlesSelector extends React.Component {
  getArticleSelector(article) {
    return (
      <ArticleContainer
        key={article.index}
        isSelected={article.index === this.props.selectedArticleIndex}
      >
        <Img src={article.logo} alt={article.logoCaption} />
      </ArticleContainer>
    );
  }

  render() {
    return (
      <Container>
        <ControlContainer>
          <ControlImg src={arrowLeft} onClick={() => this.props.goPrevArticle()} />
        </ControlContainer>
        <SubContainer>
          {this.props.articlesList.map(article => this.getArticleSelector(article))}
        </SubContainer>
        <ControlContainer>
          <ControlImg src={arrowRight} onClick={() => this.props.goNextArticle()} />
        </ControlContainer>
      </Container>
    );
  }
}

ArticlesSelector.propTypes = {
  articlesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedArticleIndex: PropTypes.number.isRequired,
  goNextArticle: PropTypes.func.isRequired,
  goPrevArticle: PropTypes.func.isRequired,
};

export default ArticlesSelector;
