import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dots from './v2/Dots';
import { margins, fontSizes, colors, mobileThresholdPixels } from './v2/styledComponents';

const Container = styled.div`
  padding: ${margins.s} 100px ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 0px ${margins.l};
    max-width: 100%;
    overflow: hidden;
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  font-family: Roboto;
  color: ${colors.navy};
`;

const DotsContainer = styled.div`
  margin-top: ${margins.s}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${props => !props.noMaring && `margin-top: ${margins.xl}`};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column-reverse;
    text-align: center;
    ${props => !props.noMaring && `margin: ${margins.m} 0px`};
  }
`;

const Title2 = styled.h2`
  color: ${colors.navy};
  font-size: ${fontSizes.xl};
  margin: 0px;
  font-weight: 500;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
    margin-top: ${margins.s};
  }
`;

const A = styled.a`
  color: ${colors.navy};
  font-size: ${fontSizes.l};

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    font-size: ${fontSizes.m};
  }
`;

const ArticlePhoto = styled.img`
  height: 600px;
  width: 50%;
  object-fit: cover;
  margin-right: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    height: 400px;
    width: auto;
    margin-right: 0px;
  }
`;

function wrap(text) {
  if (text[0] === '"' || text[0] === '“') return <span>{text}</span>;
  return (<span>«{text}»</span>);
}

const PressArticle = ({ article }) => (
  <Container>
    {article.articles.map((subArticle, index) =>
      <ArticleContainer key={subArticle.title}>
        {index !== 0 && <DotsContainer><Dots big /></DotsContainer>}
        {subArticle.title && <Title2>{wrap(subArticle.title)}</Title2>}
        <Row noMaring={subArticle.title === null}>
          {subArticle.articleImage && <ArticlePhoto src={subArticle.articleImage} alt={subArticle.articleImageAlt} />}
          {subArticle.content}
        </Row>
        {subArticle.articleLink && <A
          style={{ color: 'inherit' }}
          target="_blank" rel="noreferrer noopener"
          href={subArticle.articleLink}
        >
          Lire l‘article complet
        </A>}
      </ArticleContainer>,
    )}
  </Container>
);

PressArticle.propTypes = {
  article: PropTypes.shape({}).isRequired,
};

export default PressArticle;
